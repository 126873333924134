import React from 'react';

const Features = () => {
    const features = [
        {
            icon: <i className="fas fa-laptop-code text-[#f4f3f3] text-3xl"></i>, 
            title: "Création de sites modernes",
            desc: "Nous concevons des sites web élégants et performants, adaptés à vos besoins professionnels."
        },
        {
            icon: <i className="fas fa-mobile-alt text-[#f4f3f3] text-3xl"></i>, 
            title: "Design responsive",
            desc: "Votre site parfaitement adapté à tous les types d’écrans : téléphone, tablette et ordinateur. Pour garantir une expérience utilisateur optimale."
        },
        {
            icon: <i className="fas fa-chart-line text-[#f4f3f3] text-3xl"></i>, 
            title: "Optimisation SEO",
            desc: "Boostez votre visibilité en ligne, car, avoir un site, c'est bien, mais être facilement trouvé et visible sur Google, c'est encore mieux !"
        },
    ];

    return (
        <section className="py-14 bg-[#111]">
            <div className="max-w-screen-xl mx-auto px-4 md:px-8">
                <div className="relative max-w-2xl mx-auto sm:text-center">
                    <div className="relative z-10">
                        <h3 className="text-[#f4f3f3] text-3xl font-semibold sm:text-4xl">
                            Développez votre activité en ligne
                        </h3>
                        <p className="mt-3 text-[#f4f3f3]">
                            Nous vous aidons à transformer vos idées en un site web professionnel et performant pour maximiser votre impact.
                        </p>
                    </div>
                    <div className="absolute inset-0 max-w-xs mx-auto h-44 blur-[118px]" style={{ background: "linear-gradient(152.92deg, rgba(59, 130, 246, 0.2) 4.54%, rgba(37, 99, 235, 0.26) 34.2%, rgba(59, 130, 246, 0.1) 77.55%)" }}></div>
                </div>
                <div className="relative mt-12">
                    <ul className="grid gap-8 sm:grid-cols-2 lg:grid-cols-3">
                        {
                            features.map((item, idx) => (
                                <li key={idx} className="bg-custom-gradient  space-y-3 p-4 rounded-lg shadow-sm">
                                    <div className="pb-3 text-[--slate-800]">
                                        {item.icon}
                                    </div>
                                    <h4 className="text-lg text-[#f3f4f4] font-semibold">
                                        {item.title}
                                    </h4>
                                    <p className="text-[#f3f4f4]">
                                        {item.desc}
                                    </p>
                                </li>
                            ))
                        }
                    </ul>
                </div>
            </div>
        </section>
    );
}

export default Features;
