import { useRef, useState } from "react"

const FaqsCard = (props) => {

    const answerElRef = useRef()
    const [state, setState] = useState(false)
    const [answerH, setAnswerH] = useState('0px')
    const { faqsList, idx } = props

    const handleOpenAnswer = () => {
        const answerElH = answerElRef.current.childNodes[0].offsetHeight
        setState(!state)
        setAnswerH(`${answerElH + 20}px`)
    }

    return (
        <div 
            className="space-y-3 mt-5 overflow-hidden border-b"
            key={idx}
            onClick={handleOpenAnswer}
        >
            <h4 className="cursor-pointer pb-5 flex items-center justify-between text-lg text-[#f4f3f3] font-medium">
                {faqsList.q}
                {
                    state ? (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-[#f4f3f3] ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokelinecap="round" strokelinejoin="round" stroke-width="2" d="M20 12H4" />
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 text-[#f4f3f3] ml-2" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
                        </svg>
                    )
                }
            </h4>
            <div
                ref={answerElRef} className="duration-300"
                style={state ? {height: answerH } : {height: '0px'}}
            >
                <div>
                    <p className="text-[#f4f3f3]">
                        {faqsList.a}
                    </p>
                </div>
            </div>
        </div>
    )
}

function Faq () {

    const faqsList = [
        {
            q: "Que faut-il fournir pour créer un site ?",
            a: "Je vous demande de m'envoyer les textes, photos que vous souhaitez intégrer sur chaque page de votre site, ainsi que les textes de mentions légales, CGV, et politique de confidentialité. Nous pouvons aidé dans la création de ces éléments."
        },
        {
            q: "Quelle technologie utilisez-vous pour créer le site ?",
            a: "En fonction de votre besoin, nous vous orientons vers la meilleure solution. Nous utilisons des solutions comparable à Wordpress, vous permettant de modifier votre site de façon autonome. Nous pouvons également coder votre de zéro. Cela dépend de votre projet et budget."
        },
        {
            q: "L'hébergement du site et le nom de domaine sont-ils inclus ?",
            a: "L'hébergement du site et le nom de domaine ne sont pas inclus car la facturation est annuelle de votre côté. En fonction de vos fonctionnalités, le coût s'élève autour 50€/an."
        },
        {
            q: "Peut-on régler en plusieurs fois ?",
            a: "Tous les paiements se font en 2 fois pour sécuriser l'ensemble du service, de notre côté et du votre. Un premier paiement de 50% pour déclencher la réalisation du site. Une fois le site terminé, le dernier paiement de 50% est à réaliser, pour vous donner accès à votre compte."
        },
        {
            q: "Il y a t-il une formation pour gérer son site ?",
            a: "Tout à fait, nous prévoyons 1h avec vous pour vous expliquer comment maintenir votre site, changer les textes et les images. Une maintenance technique est aussi inclus."
        }
    ]
  
    return (
        <section className="leading-relaxed max-w-screen-xl mt-12 mx-auto px-4 md:px-8">
            <div className="space-y-3 text-center">
                <h1 className="text-4xl text-[#f4f3f3] font-extrabold mx-auto md:text-5xl">
                    FAQ
                </h1>
                <p className="text-[#f4f3f3] max-w-lg mx-auto text-lg">
                Voici les questions les plus fréquemment posées. Si vous avez encore des doutes, n'hésitez pas à nous contacter.</p>
            </div>
            <div className="mt-14 max-w-2xl mx-auto">
                {
                    faqsList.map((item, idx) => (
                        <FaqsCard
                            idx={idx}
                            faqsList={item}
                        />
                    ))
                }
            </div>
        </section>
    )
}

export default Faq;