import React from "react";
import Eddy from '../../img/IMG_0820.png'
import Nina from '../../img/nina-profil.webp'
import Sophie from '../../img/Sophie.png'
import AntreConnaissance from '../../img/logoNav.webp'
import Amine from '../../img/amine.jpg'
import "./InfiniteMovingCards.css";

function InfiniteMovingCards() {
    const testimonials = [
        {
            avatar: Eddy,
            name: "Eddy Cherif",
            title: "Praticien médecine chinoise",
            quote: "J'ai eu l'occasion de faire mon premier site pro avec l'atelier december, l'accompagnement est top et le résultat correspond parfaitement à mes attentes ; merci encore !"
        },
        {
            avatar: Nina,
            name: "Nina Carducci",
            title: "Photographe",
            quote: "Un travail de SEO et retouche design au top. Des délais respectés et un service client impeccable. Notre site web attire désormais beaucoup plus de visiteurs."
        },
        {
            avatar: Sophie,
            name: "Sophie Bluel",
            title: "Architecte",
            quote: "Une collaboration fluide et professionnelle du début à la fin. Le rendu dépasse mes attentes."
        },
        {
            avatar: AntreConnaissance,
            name: "Antre-connaissance",
            title: "Association",
            quote: "Nous voulions un site avec une identité forte, qui reflète l'identité de l'association. Après quelques aller-retours pour peaufiner les details, nous sommes aujourd'hui fier du résultat. Merci à vous."
        },
        {
            avatar: Amine,
            name: "Tonton Tajwid",
            title: "Professeur particulier",
            quote: "J'avais besoin d'une page vitrine pour présenter mes cours de manière simple et clair, le travail à été rapide et conforme à mes demandes. Je suis satisfait et je recommande."
        },
   
    ];

    return (
        <div className="infinite-moving-cards-container">
            <h2 className="infinite-moving-cards-title text-3xl font-semibold sm:text-4xl">
                Nos clients en parlent le mieux
                </h2>
            <div className="infinite-moving-cards-wrapper">
                <div className="infinite-moving-cards scroll-left">
                    {[...testimonials, ...testimonials].map((item, idx) => (
                        <div key={idx} className="infinite-moving-card bg-custom-gradient">
                            <div className="infinite-moving-card-header">
                                <img src={item.avatar} alt={item.name} className="infinite-moving-card-avatar" />
                                <div>
                                    <h3>{item.name}</h3>
                                    <p>{item.title}</p>
                                </div>
                            </div>
                            <div className="infinite-moving-card-content">
                                <p>{item.quote}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div className="infinite-moving-cards-wrapper">
                <div className="infinite-moving-cards scroll-right">
                    {[...testimonials, ...testimonials].reverse().map((item, idx) => (
                        <div key={idx} className="infinite-moving-card bg-custom-gradient">
                            <div className="infinite-moving-card-header">
                                <img src={item.avatar} alt={item.name} className="infinite-moving-card-avatar" />
                                <div>
                                    <h3>{item.name}</h3>
                                    <p>{item.title}</p>
                                </div>
                            </div>
                            <div className="infinite-moving-card-content">
                                <p>{item.quote}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default InfiniteMovingCards;