import React from 'react';
import Eddy from '../../img/IMG_0820.png'
import './WelcomeBanner.css';

function WelcomeBanner() {
  return (
    <section className="WelcomeBanner relative">
      <div
        className="absolute inset-0 m-auto max-w-xs h-[357px] blur-[118px] sm:max-w-md md:max-w-lg"
        style={{ background: 'linear-gradient(106.89deg, rgba(255, 255, 255, 0.2) 0%, rgba(192, 192, 192, 0.3) 100%)' }}
      ></div>
      <div className="relative z-7 max-w-screen-xl mx-auto px-4 py-16 md:px-8"> {/* py-16 réduit l'écart */}
        <div className="space-y-5 max-w-4xl mx-auto text-center">
          <h2 className="text-4xl text-[#f4f3f3] font-extrabold mx-auto md:text-5xl">
            Un site <span className="inline-block transform bg-custom-gradient rounded-full px-4 pb-2">
            pro
        </span>, un design qui vous <span className="inline-block transform bg-custom-gradient rounded-full px-4 pb-2">
        ressemble
        </span> et un tarif adapté à votre <span className="inline-block transform bg-custom-gradient rounded-full px-4 pb-2">
        budget
        </span>.
          </h2>
          <p className="max-w-2xl mx-auto text-[#f4f3f3]">
            Transformez les visiteurs en clients.
          </p>
          <div className="justify-center items-center gap-x-3 sm:flex">
          <a href="https://calendly.com/atelierdecember/appel-de-decouverte-pour-la-creation-de-site-web">
              <button className="flex items-center justify-center gap-x-2 py-2.5 px-4 mt-3 w-full text-sm text-[#f4f3f3] font-bold bg-custom-gradient hover:text-[#f4f3f3]  duration-150 rounded-lg sm:mt-0 sm:w-auto">
                Prendre rendez-vous gratuitement
              </button>
            </a>
            <div className="flex items-center gap-x-2 py-2.5 px-4 mt-3 w-full justify-center sm:mt-0 sm:w-auto">
            <span className="text-[#f4f3f3] font-bold text-sm">5/5 sur</span>
            <span className="flex">
              <span className="text-[#4285F4] font-bold">G</span> {/* Bleu */}
              <span className="text-[#EA4335] font-bold">o</span> {/* Rouge */}
              <span className="text-[#FBBC05] font-bold">o</span> {/* Jaune */}
              <span className="text-[#4285F4] font-bold">g</span> {/* Bleu */}
              <span className="text-[#34A853] font-bold">l</span> {/* Vert */}
              <span className="text-[#EA4335] font-bold">e</span> {/* Rouge */}
            </span>
              <div className="flex text-yellow-400">
                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927a1 1 0 011.902 0l1.357 4.18a1 1 0 00.95.69h4.39a1 1 0 01.592 1.81l-3.564 2.59a1 1 0 00-.364 1.118l1.357 4.18a1 1 0 01-1.538 1.118L10 15.347l-3.384 2.462a1 1 0 01-1.538-1.118l1.357-4.18a1 1 0 00-.364-1.118L2.507 9.607a1 1 0 01.592-1.81h4.39a1 1 0 00.95-.69l1.357-4.18z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927a1 1 0 011.902 0l1.357 4.18a1 1 0 00.95.69h4.39a1 1 0 01.592 1.81l-3.564 2.59a1 1 0 00-.364 1.118l1.357 4.18a1 1 0 01-1.538 1.118L10 15.347l-3.384 2.462a1 1 0 01-1.538-1.118l1.357-4.18a1 1 0 00-.364-1.118L2.507 9.607a1 1 0 01.592-1.81h4.39a1 1 0 00.95-.69l1.357-4.18z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927a1 1 0 011.902 0l1.357 4.18a1 1 0 00.95.69h4.39a1 1 0 01.592 1.81l-3.564 2.59a1 1 0 00-.364 1.118l1.357 4.18a1 1 0 01-1.538 1.118L10 15.347l-3.384 2.462a1 1 0 01-1.538-1.118l1.357-4.18a1 1 0 00-.364-1.118L2.507 9.607a1 1 0 01.592-1.81h4.39a1 1 0 00.95-.69l1.357-4.18z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927a1 1 0 011.902 0l1.357 4.18a1 1 0 00.95.69h4.39a1 1 0 01.592 1.81l-3.564 2.59a1 1 0 00-.364 1.118l1.357 4.18a1 1 0 01-1.538 1.118L10 15.347l-3.384 2.462a1 1 0 01-1.538-1.118l1.357-4.18a1 1 0 00-.364-1.118L2.507 9.607a1 1 0 01.592-1.81h4.39a1 1 0 00.95-.69l1.357-4.18z" />
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" viewBox="0 0 20 20" fill="currentColor">
                  <path d="M9.049 2.927a1 1 0 011.902 0l1.357 4.18a1 1 0 00.95.69h4.39a1 1 0 01.592 1.81l-3.564 2.59a1 1 0 00-.364 1.118l1.357 4.18a1 1 0 01-1.538 1.118L10 15.347l-3.384 2.462a1 1 0 01-1.538-1.118l1.357-4.18a1 1 0 00-.364-1.118L2.507 9.607a1 1 0 01.592-1.81h4.39a1 1 0 00.95-.69l1.357-4.18z" />
                </svg>
              </div>
            </div>
          
          </div>
        </div>
      </div>
      <div className="max-w-screen-xl mx-auto px-2 md:px-8 mt-18">
        <div className="max-w-3xl mx-auto">
          <figure>
            <blockquote>
              <p className="text-[#f4f3f3] text-xl text-center font-semibold sm:text-2xl">
                “J'ai eu l'occasion de faire mon premier site pro avec Atelier December, l'accompagnement est top et le résultat correspond parfaitement à mes attentes ; merci encore !“
              </p>
            </blockquote>
            <div className="flex justify-center items-center gap-x-4 mt-6">
              <img src={Eddy} alt="EddyCherif" className="w-16 h-16 rounded-full" />
              <div>
                <span className="block text-[#f4f3f3] font-semibold">Eddy C.</span>
                <span className="block text-[#f4f3f3] text-sm mt-0.5">Praticien en médecine chinoise</span>
              </div>
            </div>
          </figure>
        </div>
      </div>
    </section>
  );
}

export default WelcomeBanner;
